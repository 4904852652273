/* App.css */

.App {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 15vh;
  padding-left: 20px;
  padding-right: 20px;
}

.PanelX-container {
  margin-bottom: 5vh;
  width: 100%;
}

/* Menu bar */
.menu-bar {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 20vw;
  padding: 2vh 1.5vh;
}

.menu-item {
  color: #fff;
  margin-left: 1.2vw;
  text-decoration: none;
  font-weight: bold;
  font-size: 2vh;
  font-family: Arial, sans-serif;
  position: relative;
}

.menu-item:hover, .active {
  text-decoration: none;
}

.menu-item::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.menu-item:hover::after, .active::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Blog container */
.blog-container {
  max-width: 800px;
  margin-top: 1vh;
  line-height: 1.6;
  font-family: Arial, sans-serif;
}

.blog-container h2, 
.blog-container h3 {
  margin-top: 5rem;
  margin-bottom: 1rem; /* Increase this value to add more space below */
  padding-bottom: 1rem; /* This adds extra padding to the bottom */
}



.blog-metadata {
  color: grey;
}

.blog-metadata-bottom {
  margin-top: 8vh;
  padding-left: 0;
  padding-bottom: 8vh;
  color: grey;
}

.blog-container .blog-metadata {
  margin-bottom: 3rem;
}

.blog-container p {
  margin-bottom: 2em;
}

.blog-image {
  width: 100%;
  height: auto;
  margin-bottom: 1.5em;
  border-radius: 5px;
}

.element::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .menu-bar {
    top: 0;
    right: 5vw;
    padding: 2vh 2vw;
  }

  .menu-item {
    font-size: 4vw; /* Adjusted for mobile */
    margin-left: 3vw;
  }

  .PanelX-container {
    margin-bottom: 3vh;
  }

  .blog-container h1 {
    font-size: 2em;
  }
  
  .App {
    padding-top: 10vh;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .PanelX-container > div > div {
    width: 90vw; /* PanelX width adjustment for mobile */
    word-wrap: break-word; /* Ensure long words or titles break and don't overflow */
  }
  
  .home h1 {
    font-size: 6vw; /* Adjusted font size for mobile */
    padding: 0 5vw; /* Added padding to prevent cutting off */
    word-wrap: break-word;
  }

  .home ul {
    padding-left: 5vw; /* Padding to prevent text cut-off */
    padding-right: 5vw;
  }
}

@media (max-width: 480px) {
  .menu-bar {
    justify-content: center;
    right: 0;
  }

  .menu-item {
    font-size: 5vw;
    margin-left: 4vw;
  }

  .PanelX-container > div > div {
    width: 100vw; /* Full-width for small screens */
    word-wrap: break-word; /* Ensure long words or titles break and don't overflow */
  }

  .blog-container {
    width: 100%;
  }
  
  .blog-container h1 {
    font-size: 1.8em;
  }
  
  .home h1 {
    font-size: 7vw; /* Further adjusted font size for smaller screens */
    padding: 0 10vw; /* More padding to ensure no cut-off */
    word-wrap: break-word;
  }

  .home ul {
    padding-left: 5vw;
    padding-right: 5vw;
  }
}
